import React from 'react'
import SubLayout from '../../components/Layout/SubLayout'
import strings from "../../components/Language";
import _ from 'lodash';

//  CSS
import './../../assets/styles/style.scss';
import NoDataFound from './../../assets/images/icons/no_data_found.svg';

import { Button } from 'antd';
import Moment from 'react-moment';
import { toast } from 'react-toastify';
import axios from 'axios';
import { api } from '../../components/config/api.js';
import { getToken, storeUserData } from '../../components/Util';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

class WithdrawalDetailsScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            access_token: "",
            user_data: {},
            data: [],
            nextPageLink: "",
            prevPageLink: "",
        }
    }

    async componentDidMount() {
        var token = await getToken();
        if (token) {
            await storeUserData(token);
            this.setState({ access_token: token });
            setTimeout(() => {
                this.getData();
            });
        }
    }

    getData() {
        var self = this;
        axios({
            method: 'GET',
            url: api.withdraw_report,
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json'
            }
        }).then(function (ress) {

            if (ress.data.data && ress.data.data.length > 0) {
                self.setState({
                    data: ress.data.data,
                    nextPageLink: ress.data.links.next,
                    prevPageLink: ress.data.links.prev,
                });
            } else {
                if (ress.data.validation && ress.data.validation.length !== 0) {
                    _.forEach(ress.data.validation, function (value) {
                        if (value[0]) {
                            toast.error(value[0]);
                        }
                    });
                } else {
                    toast.error(ress.data.message);
                }
            }
        }).catch(function (e) {
            //toast.error(e.message);
        });
    };

    getNextData(API) {
        if (!API) {
            toast.error(strings.URI_not_found);
            return false;
        }
        var self = this;
        self.setState({
            nextPageLink: "",
            prevPageLink: "",
        });
        axios({
            method: 'GET',
            url: API,
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json'
            }
        }).then(function (ress) {

            if (ress.data.data && ress.data.data.length > 0) {
                self.setState({
                    data: [...self.state.data, ...ress.data.data],
                    nextPageLink: ress.data.links.next,
                    prevPageLink: ress.data.links.prev,
                });
            } else {
                if (ress.data.validation && ress.data.validation.length !== 0) {
                    _.forEach(ress.data.validation, function (value) {
                        if (value[0]) {
                            toast.error(value[0]);
                        }
                    });
                } else {
                    toast.error(ress.data.message);
                }
            }
        }).catch(function (e) {
            //toast.error(e.message);
        });
    };

    render() {

        return (
            <>
                <SubLayout ref={this.wrapper} data={{ "title": strings.withdrawal_details }}>
                    <div className='offset-md-4 col-md-4' style={{ marginTop: 20, paddingInline: 5 }}>
                        {this.state.data && this.state.data.length > 0 ?
                            <>
                                <List>
                                    {
                                        this.state.data.map((item, i) =>
                                            <ListItem disablePadding key={i}>
                                                <div className={`api_list_back_wallet`} style={{ width: "100%", marginTop: 10, }}>
                                                    <div className="list_item" style={{ fontSize: 14, margin: 0, padding: 10, paddingBlock: 10, borderRadius: 5, background: "#07051f" }}>
                                                        <div className='flex-center' style={{ justifyContent: "space-between", marginTop: 10 }}>
                                                            <div className='col-6'>
                                                                <div>{strings.withdrawal}</div>
                                                                <div>{item.amount ?? 0} {strings.usdt}</div>
                                                            </div>
                                                            <div className='col-6' style={{ textAlign: "right" }}>
                                                                <Moment format="DD MMMM YYYY">{item.created_at}</Moment>
                                                            </div>
                                                        </div>
                                                        <div className='flex-center' style={{ justifyContent: "space-between", marginTop: 10 }}>
                                                            <div className='col-6'>{strings.quantity}</div>
                                                            <div className='col-6' style={{ textAlign: "right" }}>{item.amount ?? 0} {strings.usdt}</div>
                                                        </div>
                                                        <div className='flex-center' style={{ justifyContent: "space-between", marginTop: 0 }}>
                                                            <div className='col-6' >{strings.chain_name}</div>
                                                            <div className='col-6' style={{ textAlign: "right" }}>BEP20</div>
                                                        </div>
                                                        <div style={{ justifyContent: "space-between", marginTop: 0 }}>
                                                            <div className='' >{strings.deposit_address}:</div>
                                                            <div className='' style={{}}>{item.wallet ?? ""}</div>
                                                        </div>
                                                        <div className='flex-center' style={{ justifyContent: "space-between", marginTop: 0 }}>
                                                            <div className='col-6' >{strings.status}</div>
                                                            <div className={(item.status_data && item.status_data.class) ? `col-6 ${item.status_data.class}` : 'col-6'} style={{ textAlign: "right" }}>
                                                                {(item.status_data && item.status_data.name) ? item.status_data.name : ""}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ListItem>
                                        )
                                    }
                                </List>

                                {this.state.nextPageLink ?
                                    <div className='flex-center' style={{ justifyContent: "center" }}>
                                        <Button onClick={() => this.getNextData(api.nextPageLink)} type="primary" className='btn rounded-sm btn-m text-uppercase font-700 mt-4 mb-3 btn-full '
                                            style={{ fontSize: 14, paddingBlock: 10, background: '#446CE3', color: "#FFF" }}>
                                            {strings.Load_More}
                                        </Button>
                                    </div>
                                    : null}

                            </>
                            : <div className='text-center' style={{ padding: 20, borderRadius: 5 }}>
                                <img src={NoDataFound} alt="Api2"></img>
                            </div>}
                    </div>
                </SubLayout>
            </>
        );
    }
}

export default WithdrawalDetailsScreen;
