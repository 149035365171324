import React from 'react';
import SubLayout from '../../components/Layout/SubLayout'
import strings from "../../components/Language";
import "./profile.scss"

import ArrowForword from './../../assets/images/icons/arrow_forword.svg';
import checkGreen from './../../assets/images/icons/radio-button-green.png';
import checkWhite from './../../assets/images/icons/radio-button-wwhite.png';

import { Button } from 'react-onsenui';
import Modal from '@mui/material/Modal';
class SystemSettingScreen extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isModal: false,
			lan_: localStorage.getItem("language"),
		}
	}

	Logout() {
		localStorage.clear();
		window.location.reload(false);
	}

	changeLanguage() {
		var self = this;
		if (self.state.lan_) {
			strings.setLanguage(self.state.lan_);
			localStorage.setItem("language", self.state.lan_);
			self.setState({ isModal: false, });
		}
	}

	closeModal() {
		var self = this;
		self.setState({
			isModal: false,
		});
	}

	render() {
		return (
			<SubLayout data={{ "title": strings.system_setting }}>
				<div className="profile">
					<div style={{ marginTop: 10 }}>
						{/* <div className='flex-center' style={{
							justifyContent: "space-between", padding: 10,
							borderBottomWidth: 0.5, borderBottomColor: "#8E8E8E", borderBottomStyle: "solid"
						}}>
							<div className='flex-center' style={{ flexDirection: "row" }}>
								<div style={{ marginLeft: 10 }}>
									<span style={{ fontSize: 14 }}>{strings.valuation_method}</span>
								</div>
							</div>
							<div className='flex-center'>
								<span style={{ fontSize: 14, marginRight: 15 }}>{strings.USD}</span>
								<img src={ArrowForword} alt="ArrowForword"></img>
							</div>
						</div> */}
						<div onClick={() => this.setState({ isModal: true })} className='flex-center' style={{
							justifyContent: "space-between", padding: 10, cursor: "pointer",
							borderBottomWidth: 0.5, borderBottomColor: "#8E8E8E", borderBottomStyle: "solid"
						}}>
							<div className='flex-center' style={{ flexDirection: "row" }}>
								<div style={{ marginLeft: 10 }}>
									<span style={{ fontSize: 14 }}>{strings.language}</span>
								</div>
							</div>
							<div className='flex-center'>
								<span style={{ fontSize: 14, marginRight: 15 }}>
									{(this.state.lan_ == 'en') ? strings.english : ''}
									{/* {(this.state.lan_ == 'ms') ? strings.malaysia : ''} */}
									{(this.state.lan_ == 'zh') ? strings.chinese : ''}
								</span>
								<img src={ArrowForword} alt="ArrowForword"></img>
							</div>
						</div>
						{/* <div className='flex-center' style={{
							justifyContent: "space-between", padding: 10,
							borderBottomWidth: 0.5, borderBottomColor: "#8E8E8E", borderBottomStyle: "solid"
						}}>
							<div className='flex-center' style={{ flexDirection: "row" }}>
								<div style={{ marginLeft: 10 }}>
									<span style={{ fontSize: 14 }}>{strings.theme_mode}</span>
								</div>
							</div>
							<div className='flex-center'>
								<span style={{ fontSize: 14, marginRight: 15 }}>{strings.dark_mode}</span>
								<img src={ArrowForword} alt="ArrowForword"></img>
							</div>
						</div> */}
						<div className='flex-center' style={{
							justifyContent: "space-between", padding: 10,
							borderBottomWidth: 0.5, borderBottomColor: "#8E8E8E", borderBottomStyle: "solid"
						}}>
							<div className='flex-center' style={{ flexDirection: "row" }}>
								<div style={{ marginLeft: 10 }}>
									<span style={{ fontSize: 14 }}>{strings.firmware_version}</span>
								</div>
							</div>
							<div className='flex-center'>
								<span style={{ fontSize: 14, marginRight: 15 }}>V 1.01</span>
							</div>
						</div>
						<div className='text-center'>
							<Button onClick={() => this.Logout()} className='btn rounded-sm btn-m text-uppercase font-700 mt-4 mb-3 btn-full '
								style={{ fontSize: 14, paddingBlock: 5, marginInline: 5, borderRadius: 5, background: "#446CE3" }}
							>{strings.logout}</Button>
						</div>
					</div>
				</div>

				<Modal
					open={this.state.isModal ?? false}
					className="itemModal"
					onClose={this.closeModal}
					style={{ overflow: 'scroll' }}
				>
					<>
						<div className="modal-dialog modal-dialog-centered modal-sm">
							<div className="modal-content" style={{ background: "#616162" }}>
								<div className="modal-header" style={{ width: "100%" }}>
									<span style={{ color: "#FFF" }}>{strings.language}</span>
									<button onClick={() => this.closeModal()}
										type="button" className="close" data-dismiss="modal" aria-label="Close"
										style={{ background: "transparent", borderWidth: 0 }}
									>
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
								<div className="modal-body">
									<div>
										<div onClick={() => this.setState({ lan_: "en" })} className='flex-center' style={{ padding: 10, borderBottomWidth: 0.5, borderBottomColor: "#8E8E8E", borderBottomStyle: "solid" }}>
											{(this.state.lan_ == 'en') ?
												<img src={checkGreen} alt="en" width="20" />
												:
												<img src={checkWhite} alt="en" width="20" />
											}
											<span style={{ fontSize: 14, marginLeft: 10 }}>{strings.english}</span>
										</div>
										{/* <div onClick={() => this.setState({ lan_: "ms" })} className='flex-center' style={{ padding: 10, borderBottomWidth: 0.5, borderBottomColor: "#8E8E8E", borderBottomStyle: "solid" }}>
											{(this.state.lan_ == 'ms') ?
												<img src={checkGreen} width="20" />
												:
												<img src={checkWhite} width="20" />
											}
											<span style={{ fontSize: 14, marginLeft: 10 }}>{strings.malaysia}</span>
										</div> */}
										<div onClick={() => this.setState({ lan_: "zh" })} className='flex-center' style={{ padding: 10, borderBottomWidth: 0.5, borderBottomColor: "#8E8E8E", borderBottomStyle: "solid" }}>
											{(this.state.lan_ == 'zh') ?
												<img src={checkGreen} alt="zh" width="20" />
												:
												<img src={checkWhite} alt="zh" width="20" />
											}
											<span style={{ fontSize: 14, marginLeft: 10 }}>{strings.chinese}</span>
										</div>
									</div>
									<div className='text-center'>
										<Button onClick={() => this.changeLanguage()} className='btn rounded-sm btn-m text-uppercase font-700 mt-4 mb-3 btn-full '
											style={{ fontSize: 14, paddingBlock: 5, marginInline: 5, borderRadius: 5, background: "#FFF", color: "#000" }}
										>{strings.save}</Button>
									</div>
								</div>
							</div>
						</div>
					</>
				</Modal>
			</SubLayout >
		);
	}
}

export default SystemSettingScreen;
