import React from 'react';
import SubLayout from '../../components/Layout/SubLayout';
import strings from '../../components/Language';

import Refresh from './../../assets/images/icons/refresh.svg';
import User from './../../assets/images/icons/user.svg';
import Lavel from './../../assets/images/icons/lavel.svg';
import VipMembershipRights from './../../assets/images/icons/vip_membership_rights.svg';

import DirectRecommandAward from './../../assets/images/icons/direct_recommand_award.svg';
import PeerAward from './../../assets/images/icons/peer_award.svg';
import TeamReward from './../../assets/images/icons/team_reward.svg';
import GlobalDividen from './../../assets/images/icons/global_dividen.svg';

import { toast } from 'react-toastify';
import axios from 'axios';
import { api } from './../../components/config/api.js';
import { getToken, storeUserData, getUserData } from './../../components/Util';
import _ from 'lodash';

class MemberCenterScreen extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			access_token: "",
			user_data: {},
			data: [],
			level_details: {},
			current_level: {},
			upgrade_details: {},
			is_load: false,
		}
	}

	async componentDidMount() {
		var token = await getToken();
		if (token) {
			await storeUserData(token);
			var user_data = getUserData();
			setTimeout(() => {
				this.setState({
					access_token: token,
					user_data: (user_data && user_data.data) ? user_data.data : {}
				});
				this.getData();
			});
		}
	}

	getData(isRefresh = false) {
		var self = this;
		self.setState({ is_load: true });
		if (self.state.access_token) {
			axios({
				method: 'GET',
				url: api.account_progress_all,
				headers: {
					'Authorization': 'Bearer ' + self.state.access_token,
					'Content-Type': 'application/json'
				}
			}).then(function (ress) {
				if (ress.data && ress.data.error === 200) {
					self.setState({
						data: ress.data.data,
						current_level: (ress.data.data && ress.data.data.level_details && ress.data.data.level_details.current_level) ? ress.data.data.level_details.current_level : {},
						upgrade_details: (ress.data.data && ress.data.data.upgrade_details) ? ress.data.data.upgrade_details : {},
					});
					var identifier = (ress.data.data && ress.data.data.level_details && ress.data.data.level_details.next_level && ress.data.data.level_details.next_level.identifier && (ress.data.data.level_details.next_level.id !== 0)) ? ress.data.data.level_details.next_level.identifier : "b6";
					if (identifier) {
						self.setState({
							upgrade_details_current: (ress.data.data && ress.data.data.upgrade_details && ress.data.data.upgrade_details[identifier]) ? ress.data.data.upgrade_details[identifier] : {},
						});
					}
					if (isRefresh) {
						toast.success(ress.data.message);
					}
				} else {
					if (ress.data.validation && ress.data.validation.length !== 0) {
						_.forEach(ress.data.validation, function (value) {
							if (value[0]) {
								toast.error(value[0]);
							}
						});
					} else {
						toast.error(ress.data.message);
					}
				}
				self.setState({ is_load: false });
			}).catch(function (e) {
				//toast.error(e.message);
				self.setState({ is_load: false });
			});
		}
	};

	render() {

		return (
			<SubLayout data={{ "title": strings.member_center }}>
				<div style={{ marginBottom: 30 }}>
					<div style={{ margin: 10, textAlign: "-webkit-right" }}>
						{!this.state.is_load ?
							<div onClick={() => this.getData(true)} className='flex-center' style={{ cursor: "pointer", width: "fit-content" }}>
								<img style={{ zIndex: 9, marginRight: 5 }} src={Refresh} alt="" />
								<div style={{ color: "#8e8e8e" }}>{strings.refresh}</div>
							</div>
							:
							<div className='flex-center' style={{ cursor: "pointer", width: "fit-content", backgroundColor: "" }}>
								<img style={{ zIndex: 9, marginRight: 5 }} src={Refresh} alt="" />
								<div style={{ color: "#8e8e8e" }}>{strings.loading}</div>
							</div>
						}
					</div>
					<div className="member_center_lvl_6" style={{ color: "#FFFFFF", borderRadius: 15, padding: 10, marginInline: 20, paddingBottom: 50 }}>
						<div style={{ width: "fit-content", margin: "0 auto" }}>
							<div className='flex-center'>
								<div className='' style={{ marginRight: 10, width: 30 }}>
									<img style={{ zIndex: 9 }} src={User} alt="" />
								</div>
								<div className=''>
									<div className=''><label className='' style={{ fontSize: 18 }}>{(this.state.user_data && this.state.user_data.username) ? this.state.user_data.username : ''}</label></div>
									{(this.state.current_level && this.state.current_level.identifier !== 'b6') ?
										<div className=''><label className='' style={{ fontSize: 12 }}>{strings.current_level_lower_info}</label></div>
										: null}
								</div>
							</div>
							<div className='flex-center' style={{ marginTop: 10 }}>
								<div className='' style={{ marginRight: 10, width: 30 }}>
									<img style={{ zIndex: 9 }} src={Lavel} alt="" />
								</div>
								<div className=''><label className='' style={{ fontSize: 14 }}>Lvl {(this.state.current_level && this.state.current_level.name) ? this.state.current_level.name : strings.B0}</label></div>
							</div>
							{(this.state.upgrade_details_current && this.state.upgrade_details_current.summary_note) ?
								<div className='flex-center' style={{ marginTop: 5 }}>
									<div className='' style={{ marginRight: 10, width: 30 }}></div>
									<div className=''><label className='' style={{ fontSize: 12 }}>{this.state.upgrade_details_current.summary_note}{(this.state.current_level && this.state.current_level.name) ? this.state.current_level.name : strings.B0}</label></div>
								</div>
								: null}
						</div>
					</div>
					<div style={{ position: "relative" }}>
						<div className="borderAdd" style={{ position: "absolute", top: -20, width: (window.innerWidth - 20), background: "#FFFFFF", color: "#000", padding: 10, marginInline: 10 }}>
							<div className='text-center' style={{ marginTop: 20 }}>
								<img style={{}} src={VipMembershipRights} alt="" />
							</div>

							<div style={{ marginTop: 20 }}>
								{(this.state.upgrade_details_current && this.state.upgrade_details_current.membership_rights && this.state.upgrade_details_current.membership_rights.length !== 0) ?
									<div style={{ width: "fit-content", margin: "0 auto" }}>
										{this.state.upgrade_details_current.membership_rights.map((item, i) =>
											<div key={i + 1001} className='flex-center' style={{ marginBottom: 10 }}>
												<div className='' style={{ marginRight: 10 }}>
													{item.icon === "1.png" ?
														<img style={{ zIndex: 9, marginRight: 5 }} src={DirectRecommandAward} alt="" />
														: null}
													{item.icon === "2.png" ?
														<img style={{ zIndex: 9, marginRight: 5 }} src={TeamReward} alt="" />
														: null}
													{item.icon === "3.png" ?
														<img style={{ zIndex: 9, marginRight: 5 }} src={PeerAward} alt="" />
														: null}
													{item.icon === "4.png" ?
														<img style={{ zIndex: 9, marginRight: 5 }} src={GlobalDividen} alt="" />
														: null}
												</div>
												<div className=''>
													<div className='' style={{ fontSize: 14, fontWeight: "bold" }}>{item.title ?? ''}</div>
													<div className='' style={{ fontSize: 12 }}>{item.description ?? ''}</div>
												</div>
											</div>
										)}
									</div>
									:
									<div style={{ width: "fit-content", margin: "0 auto" }}>
										<div style={{ textAlign: "center" }}>{strings.level_membership_info}</div>
									</div>
								}
								<div style={{ width: "fit-content", margin: "0 auto" }}>
									<div style={{ marginTop: 20 }}>
										<div style={{ textAlign: "center" }}>{"Required 17 B1 or above direct members, with at least 3 B5 or above direct members, AND at least 9000 B1 or above team members"}</div>
										<div style={{ textAlign: "center" }}>{"(Refer to “2a Bot Activation Comm”) &"}</div>
										<div style={{ textAlign: "center" }}>{"(Refer to “2b Bot Activation StairStep”) &"}</div>
										<div style={{ textAlign: "center" }}>{"(Refer to “2c B6 World Pool”)"}</div>
										<div style={{ textAlign: "center" }}>{"Refer to “3 Profit Sharing Comm”)"}</div>
										<div style={{ textAlign: "center" }}>{"Description: Taking 25% from profit and convert it as sharing commission"}</div>
										<div style={{ textAlign: "center" }}>{"(Refer to “3b Profit Sharing StairStep”)"}</div>
										<div style={{ textAlign: "center" }}>{"(Refer to “3c Total Profit Sharing Pool”)"}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</SubLayout >
		);
	}
}

export default MemberCenterScreen;