import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import Button from '@mui/material/Button';
import strings from '../../components/Language';
import LayoutAuthSub from '../../components/Layout/LayoutAuthSub'
import _ from 'lodash';

//  CSS
import './../../assets/styles/style.scss';
import './register.scss';
import logo from './../../assets/images/front/ctsbotLogo.png';

import { Form, Input, } from 'antd';

import { toast } from 'react-toastify';
import axios from 'axios';
import { api } from './../../components/config/api.js';
class RegisterScreen extends React.Component {

    constructor(props) {
        super(props);
        var invitationCodeData = _.split(this.props.location.search, "?invitationcode=");
        this.state = {
            email: "",
            password: "",
            password_confirmation: "",
            verification_code: "",
            mobile: "",
            otp_code: "",
            redirect: false,
            invitation_code: (invitationCodeData && invitationCodeData[1]) ? invitationCodeData[1] : "",
            timer: 60, // Initial countdown time in seconds
            otpSent: false, // Track if OTP has been sent
            resendDisabled: true, // Initially disable the resend button
        }
        this.sendOtp = this.sendOtp.bind(this);
        this.startTimer = this.startTimer.bind(this);
    }

    async componentDidMount() {
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    // Function to start or reset the timer
    startTimer() {
        this.setState({ timer: 60, resendDisabled: true });

        // Clear any existing interval before starting a new one
        if (this.interval) {
            clearInterval(this.interval);
        }

        // Start a new countdown
        this.interval = setInterval(() => {
            this.setState((prevState) => ({
                timer: prevState.timer - 1,
            }), () => {
                if (this.state.timer === 0) {
                    clearInterval(this.interval);
                    this.setState({ resendDisabled: false }); // Enable resend button
                    this.setState({ otpSent: false }); // Enable resend button
                }
            });
        }, 1000);
    }

    // Function to simulate OTP sending
    sendOtp() {
        this.setState({ otpSent: true });
        console.log("OTP sent!");

        this.sendCode()
        // Start the timer after sending OTP
        this.startTimer();
    }

    onFinish(values) {
        var self = this;
        axios({
            method: 'POST',
            url: api.register,
            data: values,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(function (ress) {
            if (ress.data && ress.data.error === 200) {
                toast.success(ress.data.message);
                self.props.history.replace({ pathname: `/register-successful` })
            } else {
                if (ress.data.validation && ress.data.validation.length !== 0) {
                    _.forEach(ress.data.validation, function (value) {
                        if (value[0]) {
                            toast.error(value[0]);
                        }
                    });
                } else {
                    toast.error(ress.data.message);
                }
                clearInterval(self.interval);
                self.setState({ resendDisabled: false }); // Enable resend button
                self.setState({ otpSent: false }); // Enable resend button
            }
        }).catch(function (e) {
            clearInterval(self.interval);
            self.setState({ resendDisabled: false }); // Enable resend button
            self.setState({ otpSent: false }); // Enable resend button
            //toast.error(e.message);
        });
    };

    sendCode() {
        var self = this;
        var json = { email: self.state.email }
        axios({
            method: 'POST',
            url: api.register_send_code,
            data: json,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(function (ress) {
            if (ress.data && ress.data.error === 200) {
                self.setState({ otp_code: ress.data.data.otp_code })
                toast.success(ress.data.message);
            } else {
                if (ress.data.validation && ress.data.validation.length !== 0) {
                    _.forEach(ress.data.validation, function (value) {
                        if (value[0]) {
                            toast.error(value[0]);
                        }
                    });
                } else {
                    toast.error(ress.data.message);
                }
                clearInterval(self.interval);
                self.setState({ resendDisabled: false }); // Enable resend button
                self.setState({ otpSent: false }); // Enable resend button
            }
        }).catch(function (e) {
            clearInterval(self.interval);
            self.setState({ resendDisabled: false }); // Enable resend button
            self.setState({ otpSent: false }); // Enable resend button
            //toast.error(e.message);
        });
    };

    onChangeEmail = (event, selectedDate) => {
        var self = this;
        self.setState({ email: event.target.value })
    };

    render() {
        const { timer, otpSent, resendDisabled } = this.state;
        if (this.state.redirect) {
            return <Link to="/register-successful" />;
        }
        return (
            <LayoutAuthSub data={{ "title": strings.register }}>
                <div className="card card-style mb-0 bg-transparent shadow-0 bg-3 mx-0 rounded-0 main_height auth_page" data-card-height="cover"
                    style={{ marginTop: 30 }}
                >
                    <div className="card-center" style={{ marginBottom: 30 }}>
                        <div className="card card-style">
                            <div className="content" style={{ margin: 20 }}>
                                <div className="col text-center">
                                    <img data-src="images/pictures/23t.jpg" className="preload-img image-logo rounded-xs entered loaded" alt="img" src={logo} data-ll-status="loaded" />
                                    <h1 className='text-center'>{strings.sign_up}</h1>
                                </div>

                                <Form
                                    className='offset-md-4 col-md-4'
                                    name="sign-in"
                                    onFinish={(data) => this.onFinish(data)}
                                    initialValues={{
                                        invitation_code: this.state.invitation_code
                                    }}
                                >
                                    <div>
                                        <label className="">Username</label>
                                        <div className="form-custom form-label form-icon mb-3">
                                            <Form.Item
                                                name="name"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: strings.please_input_your_username,
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    className="form-control rounded-xs"
                                                    placeholder={strings.enter_username}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div>
                                        <label className="">{strings.email}</label>
                                        <div className="form-custom form-label form-icon mb-3">
                                            <Form.Item
                                                name="email"
                                                rules={[
                                                    {
                                                        type: 'email',
                                                        message: strings.email_not_valid,
                                                    },
                                                    {
                                                        required: true,
                                                        message: strings.email_input,
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    onChange={this.onChangeEmail}
                                                    className="form-control rounded-xs"
                                                    placeholder={strings.email_enter}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div>
                                        <label className="">{strings.verification_code}</label>
                                        <div className='row'>
                                            <div className='col-9'>
                                                <div className="form-custom form-label form-icon mb-3">
                                                    <Form.Item
                                                        name="verification_code"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: strings.input_your_verification_code,
                                                            },
                                                            // ({ getFieldValue }) => ({
                                                            //     validator(rule, value) {
                                                            //         if (!value || self.state.otp_code === value) {
                                                            //             return Promise.resolve();
                                                            //         }
                                                            //         return Promise.reject(strings.otp_not_match);
                                                            //     },
                                                            // }),
                                                        ]}
                                                    >
                                                        <Input
                                                            className="form-control rounded-xs"
                                                            placeholder={strings.verification_code_enter}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className='col-3 p-0'>
                                                <Button className='mt-1 opt-send' variant="outlined"
                                                    onClick={this.sendOtp}
                                                    disabled={otpSent && resendDisabled}
                                                    // onClick={(data) => this.sendCode()}
                                                    style={{ padding: 5, textTransform: "none" }}>
                                                    {/* {strings.get_code} */}
                                                    {otpSent ? `Resend OTP (${timer}s)` : "Send OTP"}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: 10 }}>
                                        <label className="">{strings.password}</label>
                                        <div>
                                            <Form.Item
                                                name="password"
                                                rules={[
                                                    { required: true, message: strings.password_input },
                                                ]}
                                            >
                                                <Input.Password
                                                    className="form-control rounded-xs password-class"
                                                    placeholder={strings.please_enter_password}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: 10 }}>
                                        <label className="">{strings.retype_password}</label>
                                        <div>
                                            <Form.Item
                                                name="password_confirmation"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: strings.retype_password_input
                                                    },
                                                    ({ getFieldValue }) => ({
                                                        validator(rule, value) {
                                                            if (!value || getFieldValue('password') === value) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject(strings.retype_password_not_match);
                                                        },
                                                    }),
                                                ]}
                                            >
                                                <Input.Password
                                                    className="form-control rounded-xs password-class"
                                                    placeholder={strings.retype_password_enter}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div>
                                        <label className="">{strings.invitation_code}</label>
                                        <div className="form-custom form-label form-icon mb-3">
                                            <Form.Item
                                                name="invitation_code"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: strings.invitation_code_required
                                                    }
                                                ]}
                                            >
                                                <Input
                                                    className="form-control rounded-xs"
                                                    placeholder={strings.invitation_code_enter}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <Form.Item>
                                        <Button type="primary" className='btn rounded-sm btn-m login_button text-uppercase font-700 mt-4 mb-3 btn-full '
                                            style={{ fontSize: 14, paddingBlock: 10, background: '#446CE3', color: "#FFF", width: "100%", borderRadius: 10 }}>
                                            {strings.register_now}
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </LayoutAuthSub>


        );
    }
}

export default withRouter(RegisterScreen);
