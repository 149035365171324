import React from "react";
import { Link } from "react-router-dom";
import "./Layout.scss";
import strings from "../Language";

import home from "./../../assets/images/menu/home.png";
import quatitative from "./../../assets/images/menu/quantitative.png";
import community from "./../../assets/images/menu/community.png";
import chart from "./../../assets/images/menu/chart.png";
import profile from "./../../assets/images/menu/profile.png";
import ctcbotLogo from "./../../assets/images/front/ctsbotLogo.png";
import { Helmet } from "react-helmet";
// import ctcbotLogo from './../../assets/images/ctcbot_logo_12.png';

class Layout extends React.Component {
  constructor(props) {
    super(props);
    var headerData = this.props && this.props.data ? this.props.data : {};
    var headerTitle =
      this.props && this.props.data && this.props.data.title
        ? this.props.data.title
        : "";
    var is_logs =
      this.props && this.props.data && this.props.data.is_logs
        ? this.props.data.is_logs
        : false;
    var is_logs_b =
      this.props && this.props.data && this.props.data.is_logs_b
        ? this.props.data.is_logs_b
        : false;
    var is_logo =
      this.props && this.props.data && this.props.data.is_logo
        ? this.props.data.is_logo
        : false;
    this.state = {
      headerData: headerData,
      headerTitle: headerTitle,
      is_logs: is_logs,
      is_logo: is_logo,
      is_logs_b: is_logs_b,
    };
  }
  render() {
    return (
      <>
        <Helmet>
          <title>CTSBot | {this.state.headerTitle}</title>
        </Helmet>
        <div id="page">
          <div className="header-bar header-fixed header-app main_header">
            <div className="text-center full-width">
              <img
                className=""
                alt={strings.Home}
                src={ctcbotLogo}
                width={window.innerWidth < 668 ? window.innerWidth / 2 : 200}
              />
            </div>
            {/* {
                            this.state.is_logs ?
                            <Link to={"/orders"} style={{ position: "absolute", right: 5, top: 5 }}>Logs</Link>
                            : null
                        }
                        {
                            this.state.is_logs_b ?
                            <Link to={"/orders-b"} style={{ position: "absolute", right: 5, top: 5 }}>Logs</Link>
                            : null
                        } */}
          </div>
          <div
            id="footer-bar"
            className="footer-bar footer-bar-detached main_footer"
          >
            <Link
              to="/home"
              className={
                this.state.headerTitle.toLowerCase() === "home".toLowerCase()
                  ? "show-offline"
                  : "show-offline"
              }
            >
              <div className="">
                <img
                  className=""
                  alt={strings.Home}
                  src={home}
                  width={35}
                  height={35}
                />
              </div>
              <span>{strings.Home}</span>
            </Link>
            <Link
              to="/quatitative"
              className={
                this.state.headerTitle.toLowerCase() ===
                "quatitative".toLowerCase()
                  ? "show-offline"
                  : "show-offline"
              }
            >
              <div className="">
                <img
                  className=""
                  alt="Quatitative"
                  src={quatitative}
                  width={35}
                  height={35}
                />
              </div>
              <span>{strings.Quatitative}</span>
            </Link>
            <Link
              to={"/chart"}
              className={
                this.state.headerTitle.toLowerCase() === "chart".toLowerCase()
                  ? "show-offline"
                  : "show-offline"
              }
            >
              <div className="">
                <img
                  className=""
                  alt="Community"
                  src={chart}
                  width={35}
                  height={35}
                />
              </div>
              <span>{strings.Chart}</span>
            </Link>
            <Link
              to="/profile"
              className={
                this.state.headerTitle.toLowerCase() === "Profile".toLowerCase()
                  ? "show-offline"
                  : "show-offline"
              }
            >
              <div className="">
                <img
                  className=""
                  alt="Profile"
                  src={profile}
                  width={35}
                  height={35}
                />
              </div>
              <span>{strings.Profile}</span>
            </Link>
          </div>
          <div
            className="page-content header-clear"
            style={{
              marginTop: 10,
              overflowY: "scroll",
            }}
          >
            {this.props.children}
          </div>
        </div>
      </>
    );
  }
}
export default Layout;
