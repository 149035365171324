
import React from 'react';
import { Link } from 'react-router-dom'
import Layout from '../../components/Layout';
import strings from "../../components/Language";
import axios from 'axios';
import { getToken, storeUserData } from './../../components/Util';
import _ from 'lodash';
import { api } from './../../components/config/api.js';
import Bitcoin from './../../assets/images/icons/bitcoin.svg';
class ChartScreen extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			access_token: "",
			botLiveData: [],
			data: [],
			is_load: true,
		}
	}

	async componentDidMount() {
		var token = await getToken();
		if (token) {
			await storeUserData(token);
			setTimeout(() => {
				this.setState({ access_token: token });
				this.getData();
			});
		}
	}

	getData() {
		var self = this;
		if (self.state.access_token) {

			axios({
				method: 'GET',
				url: api.bot_report,
				headers: {
					'Authorization': 'Bearer ' + self.state.access_token,
					'Content-Type': 'application/json'
				}
			}).then(function (ress) {
				if (ress.data && ress.data.error === 200) {
					var parinames = "";
					var parinamesArray = [];
					var botData = _.filter(ress.data.data, function (o, key) {
						o.average_ = (o.total_usdt_qty > 0) ? (o.total_usdt_qty / o.total_coin_qty) : 0;
						o.is_calculate = (o.total_coin_qty > 0) ? true : false;

						if (o.pair.name && parinamesArray.indexOf(o.pair.name) == -1) {
							if (parinames) {
								parinames += `,`;
							}
							parinames += `"` + o.pair.name + `"`;
							parinamesArray.push(o.pair.name);
						}

						return o;
					});
					console.log("botData", botData);
					self.setState({ data: botData, pariNames: parinames });

					if (parinames && parinames.length != 0) {
						self.getLiveData();
						setInterval(() => {
							self.getLiveData();
						}, 10000);
					}
				}
			}).catch(function (e) {
			});
		}
	};

	getLiveData() {
		var self = this;
		if (self.state.access_token) {

			axios({
				method: 'GET',
				// url: api.pair_report,
				// url: "https://api.coincap.io/v2/assets?ids=bitcoin,ethereum,binance-coin,ripple,ripple,polkadot,ftx-token,xrp,cardano,solana",
				// url: "https://api.coincap.io/v2/assets?ids=bitcoin,ethereum,binance-coin,ripple,ripple,polkadot,xrp,cardano,solana",
				url: "https://api.binance.com/api/v3/ticker/24hr?symbols=[" + self.state.pariNames + "]",
			}).then(function (ress) {
				if (ress && ress.status == 200) {
					var data = _.filter(self.state.data, function (o) {
						var finalLiveData = _.find(ress.data, { symbol: o.pair.name });
						if (finalLiveData) {
							o.liveData = finalLiveData;
						}
						return o;
					})
					self.setState({ get_live_bot: true });
				}
				self.setState({ is_load: false });
			}).catch(function (e) {
			});
		}
	};

	render() {
		return (
			<Layout data={{ "title": strings.Chart }}>
				<>
					{!this.state.is_load ?
						<>
							{
								this.state.data.map((item, i) =>
									<div key={i + 1001} className="list_item">
										<Link to={{ pathname: `/ChartDetails/${item.pair.name}` }}>
											<div className="row">
												<div className='col-8 flex-center'>
													<img className="image_icon" src={(item.pair && item.pair.icon_url) ? item.pair.icon_url : Bitcoin} alt="Bitcoin" width={25} height={25} />
													<div className='width-auto margin-left-1'>
														<span className=''>{(item.pair && item.pair.name) ? item.pair.name : ''}</span>
													</div>
												</div>
												<div className='col-4 avg_label'>
													<div className='width-fit-content avg_price'>
														<label className={(item.liveData.priceChangePercent > 0) ? "text_up" : "text_down"}>
															{item.liveData.priceChangePercent ? Number.parseFloat(item.liveData.priceChangePercent).toFixed(2) : "0"}%
														</label>
													</div>
												</div>
											</div>
											<div className="row list_details gray_color" style={{ fontSize: 12 }}>
												<div className='col-6'>
													<div className='width-auto margin-left-1'>
														<label>{strings.latest_price}: <span className=''>
															{(item.liveData && item.liveData.lastPrice) ? Number.parseFloat(item.liveData.lastPrice).toFixed(5) : '0.00'}
														</span></label>
													</div>
												</div>
											</div>
										</Link>
									</div>
								)
							}
						</>
						: null
					}
				</>
			</Layout>
		);
	}
}
export default ChartScreen;
