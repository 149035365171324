import React from 'react'
import SubLayout from '../../components/Layout/SubLayout';
import strings from '../../components/Language';

//  CSS
import './../../assets/styles/style.scss';

import { toast } from 'react-toastify';
import axios from 'axios';
import { api } from './../../components/config/api.js';
import { getToken, storeUserData } from './../../components/Util';
import _ from 'lodash';

class NotificationScreen extends React.Component {
    constructor(props) {
        super(props);
        this.wrapper = React.createRef();
        this.state = {
            access_token: "",
            user_data: {},
        }
    }

    async componentDidMount() {
        var token = await getToken();
        if (token) {
            await storeUserData(token);
            this.setState({ access_token: token });
            setTimeout(() => {
                this.getData();
            });
        }
    }

    getData() {
        var self = this;

        axios({
            method: 'GET',
            url: api.profile_show,
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json'
            }
        }).then(function (ress) {
            if (ress.data && ress.data.error === 200) {

                self.setState({ user_data: ress.data.data });
            } else {
                if (ress.data.validation && ress.data.validation.length !== 0) {

                    _.forEach(ress.data.validation, function (value) {
                        if (value[0]) {
                            toast.error(value[0]);
                        }
                    });
                } else {
                    toast.error(ress.data.message);
                }
            }
        }).catch(function (e) {
            //toast.error(e.message);
        });
    };

    copyCode() {
        navigator.clipboard.writeText((this.state.user_data && this.state.user_data.invitation_code) ? this.state.user_data.invitation_code : '');
        toast.success(strings.code_copy_seccessfully);
    }

    copyRegistrationLink() {
        if (this.state.user_data && this.state.user_data.invitation_code) {
            navigator.clipboard.writeText(window.location.origin + `/register?invitationcode=${this.state.user_data.invitation_code}`);
            toast.success(strings.link_copy_seccessfully);
        }
    }

    render() {

        return (
            <>
                <SubLayout ref={this.wrapper} data={{ "title": strings.Notifications }}>
                    <h3 className='text-center text-danger'>{strings.no_notifications}</h3>
                </SubLayout>
            </>
        );
    }
}

export default NotificationScreen;
