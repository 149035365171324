const BaseURL = 'https://api.ctsbot.com';
const GuestURL = BaseURL + '/api/';
const UserURL = BaseURL + '/api/user/';

export const api = {
    SiteURL: BaseURL,

    /** Guest API */
    app_version: GuestURL + "app/version/android",

    login: GuestURL + "login",
    login_with_mobile: GuestURL + "login-with-mobile",
    login_with_email: GuestURL + "login-with-email",
    login_send_otp: GuestURL + "login/send-code",
    register: GuestURL + "register",
    register_send_code: GuestURL + "register/send-code",
    forgot_password: GuestURL + "forgot-password",
    forgot_password_send_code: GuestURL + "forgot-password/send-code",

    /** User API | Auth API */
    balance: UserURL + "balance",
    profile_show: UserURL + "profile/show",
    profile_update: UserURL + "profile/update",
    profile_activate: UserURL + "profile/activate",
    password_update: UserURL + "password/update",
    profile_update_avatar: UserURL + "profile/update-avatar",
    email_update: UserURL + "email/update",
    email_update_send_code: UserURL + "email/send-code",
    transaction_password_update: UserURL + "transaction-password/update",
    transaction_password_send_code: UserURL + "transaction-password/send-code",

    bind_trading_platform: UserURL + "bind-trading-platform",
    bind_trading_platform_store: UserURL + "bind-trading-platform/store",
    bind_trading_platform_send_code: UserURL + "bind-trading-platform/send-code",
    bind_trading_platform_delete: UserURL + "bind-trading-platform/delete",

    wallet_address_report: UserURL + "wallet-address/report",
    wallet_address_store: UserURL + "wallet-address/store",
    bank_report: UserURL + "bank/report",
    bank_store: UserURL + "bank/store",

    usdt_history: UserURL + "usdt-history",
    deposit_report: UserURL + "deposit/report",
    // deposit_get_wallet_address: UserURL + "deposit/get-wallet-address",
    deposit_get_wallet_address: UserURL + "deposit/store",
    deposit_bep20: UserURL + "deposit-bep20",
    withdraw_report: UserURL + "withdraw/report",
    withdraw_send_code: UserURL + "withdraw/send-code",
    withdraw_store: UserURL + "withdraw/store",
    transfer_report: UserURL + "transfer/report",
    transfer_store: UserURL + "transfer/store",
    transfer_send_code: UserURL + "transfer/send-code",
    transfer_gas_fee_report: UserURL + "transfer-gas/report",
    transfer_gas_fee_store: UserURL + "transfer-gas/store",
    transfer_gas_fee_send_code: UserURL + "transfer-gas/send-code",

    pair_report: UserURL + "pair/report",
    
    bot: UserURL + "dca-bot/",
    bot_report: UserURL + "dca-bot/report",
    bot_report_active: UserURL + "dca-bot/report-active",
    bot_margin_configuration: UserURL + "dca-bot/margin-configuration",
    
    botb: UserURL + "boll-bot/",
    botb_report: UserURL + "boll-bot/report",
    botb_report_active: UserURL + "boll-bot/report-active",
    botb_margin_configuration: UserURL + "boll-bot/margin-configuration",
    botb_orders: UserURL + "boll-bot/bot-orders",

    order_report: UserURL + "order/report",
    order_report_b: UserURL + "order/report-b",
    profit_report: UserURL + "profit/report",

    activation_reward: UserURL + "report/activation-reward",
    profit_reward: UserURL + "report/profit-reward",

    slider: UserURL + "slider",
    team: UserURL + "team",

    account_progress_current: UserURL + "account-progress/current",
    account_progress_all: UserURL + "account-progress/all",

    announcement: UserURL + "announcement",
    reward: UserURL + "reward",

    gas_history: UserURL + "gas-history",
    convert_credit: UserURL + "convert-credit/store",
}