import React from 'react'
import SubLayout from '../../components/Layout/SubLayout';
import strings from "../../components/Language";

//  CSS
import './../../assets/styles/style.scss';

import { Button } from 'react-onsenui';
import { Link } from 'react-router-dom';
import QRCode from "react-qr-code";

import { toast } from 'react-toastify';
import axios from 'axios';
import { api } from './../../components/config/api.js';
import { getToken, storeUserData } from './../../components/Util';
import _ from 'lodash';

class DepositScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            access_token: "",
            data: []
        }
    }

    async componentDidMount() {
        var token = await getToken();
        if (token) {
            await storeUserData(token);
            setTimeout(() => {
                this.setState({ access_token: token });
                this.getData();
            });
        }
    }

    copyLink() {
        if ((this.state.data && this.state.data.deposit && this.state.data.deposit.in_wallet) ? this.state.data.deposit.in_wallet : '') {
            navigator.clipboard.writeText((this.state.data && this.state.data.deposit && this.state.data.deposit.in_wallet) ? this.state.data.deposit.in_wallet : '');
            toast.success(strings.code_copy_seccessfully);
        } else {
            toast.error(strings.wallet_address_not_foud);
        }
    }

    getData() {
        var self = this;
        if (self.state.access_token) {
            axios({
                method: 'GET',
                url: api.deposit_bep20,
                headers: {
                    'Authorization': 'Bearer ' + self.state.access_token,
                    'Content-Type': 'application/json'
                }
            }).then(function (ress) {
                if (ress.data && ress.data.error === 200) {
                    console.log("ress.data.data:::::::::", ress.data.data);

                    self.setState({ data: ress.data.data });
                } else {
                    if (ress.data.validation && ress.data.validation.length !== 0) {
                        _.forEach(ress.data.validation, function (value) {
                            if (value[0]) {
                                toast.error(value[0]);
                            }
                        });
                    } else {
                        toast.error(ress.data.message);
                    }
                }
            }).catch(function (e) {
                //toast.error(e.message);
            });
        }
    };

    render() {

        return (
            <>
                <SubLayout data={{ "title": strings.deposit }}>
                    <div className='offset-md-4 col-md-4' style={{ marginTop: 20, paddingInline: 5 }}>
                        <div className="text-right">
                            <Link to={"/deposit-details"} style={{ marginRight: 10 }} >{strings.deposit_details}</Link>
                        </div>
                        <div className="flex-center">
                            {strings.chain_name}
                            <span style={{ borderWidth: 1, borderStyle: "solid", borderColor: "#446CE3", paddingBlock: 5, paddingInline: 10, marginInline: 10, borderRadius: 5 }}>BEP20</span>
                        </div>
                        <div className="col text-center" style={{ marginBlock: 10 }}>{strings.USDT_deposit_address}</div>
                        <div className="col text-center qr_code">
                            <QRCode value={(this.state.data && this.state.data.deposit && this.state.data.deposit.in_wallet) ? this.state.data.deposit.in_wallet : ''} />
                        </div>
                        <div className='text-center' style={{ marginTop: 10, marginBottom: 20 }}>
                            {strings.address_can_only_receive_USDT}
                        </div>
                        <div className='border_'></div>
                        <div className='text-center' style={{ marginTop: 20 }}>
                            {(this.state.data && this.state.data.deposit && this.state.data.deposit.in_wallet) ? this.state.data.deposit.in_wallet : ''}
                        </div>
                        <div className="col text-center" style={{ marginTop: 20 }}>
                            <Button onClick={() => this.copyLink()} style={{ borderRadius: 10, paddingLeft: 20, paddingRight: 20, cursor: "pointer" }}>{strings.code_link}</Button>
                        </div>
                        <div style={{ marginTop: 10, marginBottom: 20 }}>
                            {strings.risk_disclaimer}
                            <div style={{ marginTop: 10, color: "#8E8E8E" }}>
                                <div>{strings.deposit_risk_disclaimer_point_1}</div>
                                <div>{strings.deposit_risk_disclaimer_point_2}</div>
                                <div>{strings.deposit_risk_disclaimer_point_3}</div>
                            </div>
                        </div>
                    </div>
                </SubLayout>
            </>


        );
    }
}

export default DepositScreen;
