import Menulogo from "./../assets/images/front/menu-logo.svg";
import Menurobottrading from "./../assets/images/front/menu-robot-trading.svg";
import Menurobotsetup from "./../assets/images/front/menu-robot-setup.svg";
import Menuopentrades from "./../assets/images/front/menu-open-trades.svg";
import Menutradinghistory from "./../assets/images/front/menu-trading-history.svg";
import Menudownload from "./../assets/images/front/menu-download.svg";
import Menumybanner from "./../assets/images/front/menu-my-banner.svg";
import Menureferraloverview from "./../assets/images/front/menu-referral-overview.svg";
import Menumyreferral from "./../assets/images/front/menu-my-referral.svg";
import Menutransaction from "./../assets/images/front/menu-transaction.svg";
import Menuwithdraw from "./../assets/images/front/menu-withdraw.svg";
import Menugenealogyview from "./../assets/images/front/menu-genealogy-view.svg";
import Menuprofile from "./../assets/images/front/menu-profile.svg";
import Menufeedback from "./../assets/images/front/menu-feedback.svg";
import Menudarkmode from "./../assets/images/front/menu-dark-mode.svg";
import Menulogout from "./../assets/images/front/menu-logout.svg";
import Cryptoeth from "./../assets/images/front/crypto-eth.svg";
import Cryptobnb from "./../assets/images/front/crypto-bnb.svg";
import Cryptoxrp from "./../assets/images/front/crypto-xrp.svg";
import Cryptodoge from "./../assets/images/front/crypto-doge.svg";
import Cryptosol from "./../assets/images/front/crypto-sol.svg";
import Cryptodai from "./../assets/images/front/crypto-dai.svg";
import Cryptodot from "./../assets/images/front/crypto-dot.svg";
import Cryptomatic from "./../assets/images/front/crypto-matic.svg";
import Cryptoltc from "./../assets/images/front/crypto-ltc.svg";
import Cryptobch from "./../assets/images/front/crypto-bch.svg";
import Cryptoavax from "./../assets/images/front/crypto-avax.svg";
import Cryptolink from "./../assets/images/front/crypto-link.svg";
import Cryptoxlm from "./../assets/images/front/crypto-xlm.svg";
import Cryptoxmr from "./../assets/images/front/crypto-xmr.svg";
import Cryptouni from "./../assets/images/front/crypto-uni.svg";
import Cryptoatom from "./../assets/images/front/crypto-atom.svg";
import Cryptoetc from "./../assets/images/front/crypto-etc.svg";
import Cryptohbar from "./../assets/images/front/crypto-hbar.svg";
import Cryptoicp from "./../assets/images/front/crypto-icp.svg";
import Cryptofil from "./../assets/images/front/crypto-fil.svg";
import Cryptoldo from "./../assets/images/front/crypto-ldo.svg";
import Cryptobtc from "./../assets/images/front/crypto-btc.svg";
import Iditicon from "./../assets/images/front/edit-icon.svg";
import UploadIcon from "./../assets/images/front/upload-icon.svg";

export const images = {
  logo: require("./../assets/images/front/ctsbotLogo.png"),
  "landing-bg1": require("./../assets/images/front/landing-bg1.png"),
  "landing-planet2": require("./../assets/images/front/landing-planet2.png"),
  "landing-planet1": require("./../assets/images/front/landing-planet1.png"),
  "icon-advantage1": require("./../assets/images/front/icon-advantage1.png"),
  "icon-advantage2": require("./../assets/images/front/icon-advantage2.png"),
  "icon-advantage3": require("./../assets/images/front/icon-advantage3.png"),
  "icon-advantage4": require("./../assets/images/front/icon-advantage4.png"),
  "icon-advantage5": require("./../assets/images/front/icon-advantage5.png"),
  "icon-advantage6": require("./../assets/images/front/icon-advantage6.png"),
  "icon-advantage7": require("./../assets/images/front/icon-advantage7.png"),
  "icon-advantage8": require("./../assets/images/front/icon-advantage8.png"),
  "icon-advantage9": require("./../assets/images/front/icon-advantage9.png"),
  "landing-bg2": require("./../assets/images/front/landing-bg2.png"),
  "icon-number1-frame": require("./../assets/images/front/icon-number1-frame.png"),
  "icon-loss1": require("./../assets/images/front/icon-loss1.png"),
  "icon-number2-frame": require("./../assets/images/front/icon-number2-frame.png"),
  "icon-loss2": require("./../assets/images/front/icon-loss2.png"),
  "icon-number3-frame": require("./../assets/images/front/icon-number3-frame.png"),
  "icon-loss3": require("./../assets/images/front/icon-loss3.png"),
  "icon-number4-frame": require("./../assets/images/front/icon-number4-frame.png"),
  "icon-loss4": require("./../assets/images/front/icon-loss4.png"),
  "icon-number5-frame": require("./../assets/images/front/icon-number5-frame.png"),
  "icon-loss5": require("./../assets/images/front/icon-loss5.png"),
  fuel: require("./../assets/images/front/fuel.png"),
  "landing-reminder": require("./../assets/images/front/landing-reminder.png"),
  "landing-bg3": require("./../assets/images/front/landing-bg3.png"),
  "landing-bg-mobile1": require("./../assets/images/front/landing-bg-mobile1.png"),
  "landing-bg-mobile2": require("./../assets/images/front/landing-bg-mobile2.png"),
  "landing-bg-mobile3": require("./../assets/images/front/landing-bg-mobile3.png"),
  "bell-left": require("./../assets/images/front/bell-left.png"),
  "bell-right": require("./../assets/images/front/bell-right.png"),
  "promotion-avatar": require("./../assets/images/front/promotion-avatar.png"),
  "icon-platinum": require("./../assets/images/front/icon-platinum.png"),
  "icon-requirement1": require("./../assets/images/front/icon-requirement1.png"),
  "icon-requirement2": require("./../assets/images/front/icon-requirement2.png"),
  "account-profile": require("./../assets/images/front/account-profile.png"),
  "no-news": require("./../assets/images/front/no-news.png"),
  "logo-border": require("./../assets/images/front/logo-border.png"),
  "logo-horizontal": require("./../assets/images/front/logo-horizontal.png"),
  "general-question": require("./../assets/images/front/general-question.png"),
  "support-request": require("./../assets/images/front/support-request.png"),
  "feedback-suggestion": require("./../assets/images/front/feedback-suggestion.png"),

  /**
   * SVG IMAGES SET
   */
  "menu-logo": Menulogo,
  "menu-robot-trading": Menurobottrading,
  "menu-robot-setup": Menurobotsetup,
  "menu-open-trades": Menuopentrades,
  "menu-trading-history": Menutradinghistory,
  "menu-download": Menudownload,
  "menu-my-banner": Menumybanner,
  "menu-referral-overview": Menureferraloverview,
  "menu-my-referral": Menumyreferral,
  "menu-transaction": Menutransaction,
  "menu-withdraw": Menuwithdraw,
  "menu-genealogy-view": Menugenealogyview,
  "menu-profile": Menuprofile,
  "menu-feedback": Menufeedback,
  "menu-dark-mode": Menudarkmode,
  "menu-logout": Menulogout,
  "crypto-eth": Cryptoeth,
  "crypto-bnb": Cryptobnb,
  "crypto-xrp": Cryptoxrp,
  "crypto-doge": Cryptodoge,
  "crypto-sol": Cryptosol,
  "crypto-dai": Cryptodai,
  "crypto-dot": Cryptodot,
  "crypto-matic": Cryptomatic,
  "crypto-ltc": Cryptoltc,
  "crypto-bch": Cryptobch,
  "crypto-avax": Cryptoavax,
  "crypto-link": Cryptolink,
  "crypto-xlm": Cryptoxlm,
  "crypto-xmr": Cryptoxmr,
  "crypto-uni": Cryptouni,
  "crypto-atom": Cryptoatom,
  "crypto-etc": Cryptoetc,
  "crypto-hbar": Cryptohbar,
  "crypto-icp": Cryptoicp,
  "crypto-fil": Cryptofil,
  "crypto-ldo": Cryptoldo,
  "crypto-btc": Cryptobtc,
  "edit-icon": Iditicon,
  "upload-icon": UploadIcon,
};
