import React from 'react'
import { NavLink } from 'react-router-dom'
import { images } from '../../config/image';

class HeaderPublic extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            themeMod: 'dark'
        }
    }

    componentDidMount() {
        var themeMod = document.documentElement.getAttribute('data-bs-theme', 'dark');
        this.setState({ themeMod: themeMod });
    }

    changeTheme(e) {
        if (e.target.checked == true) {
            document.documentElement.setAttribute('data-bs-theme', 'dark');
            this.setState({ themeMod: 'dark' });
        } else {
            document.documentElement.setAttribute('data-bs-theme', 'light');
            this.setState({ themeMod: 'light' });
        }
    }

    render() {
        return (
            <>
                <nav className="landing-nav hide-nav">
                    <input type="checkbox" id="check" />
                    <label htmlFor="check" className="checkbtn">
                        <i className="fas fa-bars"></i>
                    </label>

                    <NavLink style={{ cursor: "pointer" }} to={"/"} className="landing-logo">
                        <img src={images['logo']} alt="" />
                    </NavLink>
                    <ul>
                        <li><NavLink className={({ isActive }) => (isActive ? 'active' : 'inactive')} to={"/"}>Home</NavLink></li>
                        <li><NavLink className={({ isActive }) => (isActive ? 'active' : 'inactive')} to={"/register"}>Register</NavLink></li>
                        <li><NavLink className={({ isActive }) => (isActive ? 'active' : 'inactive')} to={"/login"}>Member Login</NavLink></li>
                        {/* <li><NavLink className={({ isActive }) => (isActive ? 'active' : 'inactive')} to={"/login-admin"}>Admin Login</NavLink></li> */}
                        {/* <li><NavLink className={({ isActive }) => (isActive ? 'active' : 'inactive')} to={"/contact-us"}>Contact Us</NavLink></li> */}
                        <li>
                            <label className="landing-switch">
                                <input onChange={(e) => this.changeTheme(e)} type="checkbox" id="toggle-switch" name="toggle-switch" checked={(this.state.themeMod == 'dark') ? true : false} />
                                <span className="landing-slider"></span>
                            </label>
                        </li>
                    </ul>
                </nav>
            </>
        )
    }
}
export default HeaderPublic
