import React from 'react';
import SubLayoutReward from '../../components/Layout/SubLayoutReward';
import strings from '../../components/Language';
import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';

import "./reward.scss";

import ctcbotLogo from './../../assets/images/front/ctsbotLogo.png';
import Avatar1 from './../../assets/images/icons/avatar1.svg';

import space_001 from './../../assets/images/other/space_001.png';
import space_002 from './../../assets/images/other/space_002.png';
import space_003 from './../../assets/images/other/space_003.png';
import space_004 from './../../assets/images/other/space_004.png';
import space_005 from './../../assets/images/other/space_005.png';
import space_006 from './../../assets/images/other/space_006.png';
import space_007 from './../../assets/images/other/space_007.png';
import space_008 from './../../assets/images/other/space_008.png';
import space_009 from './../../assets/images/other/space_009.png';
import space_010 from './../../assets/images/other/space_010.png';

import rewardfooter from './../../assets/images/other/reward_footer.png';

import { toast } from 'react-toastify';
import axios from 'axios';
import { api } from '../../components/config/api.js';
import { getToken, storeUserData } from '../../components/Util';
import _ from 'lodash';
import QRCode from "react-qr-code";

class ProfitDetailsScreen extends React.Component {
    constructor(props) {
        super(props);
        var image_items = [space_001, space_002, space_003, space_004, space_005, space_006, space_007, space_008, space_009, space_010];
        this.state = {
            access_token: "",
            user_data: {},
            image_item: image_items[_.random(image_items.length - 1)],
            data: [],
        }
    }

    async componentDidMount() {
        var token = await getToken();
        if (token) {
            await storeUserData(token);
            this.setState({ access_token: token });
            setTimeout(() => {
                this.getData();
                this.getProfitData();
            });
        }
    }

    getData() {
        var self = this;

        axios({
            method: 'GET',
            url: api.profile_show,
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json'
            }
        }).then(function (ress) {
            if (ress.data && ress.data.error === 200) {
                self.setState({ user_data: ress.data.data });
            } else {
                if (ress.data.validation && ress.data.validation.length !== 0) {
                    _.forEach(ress.data.validation, function (value) {
                        if (value[0]) {
                            toast.error(value[0]);
                        }
                    });
                } else {
                    toast.error(ress.data.message);
                }
            }
        }).catch(function (e) {
            //toast.error(e.message);
        });
    };

    getProfitData() {
        var self = this;
        axios({
            method: 'GET',
            url: api.reward,
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json'
            }
        }).then(function (ress) {
            if (ress.data && ress.data.error === 200) {
                self.setState({ data: ress.data.data });
            } else {
                if (ress.data.validation && ress.data.validation.length !== 0) {
                    _.forEach(ress.data.validation, function (value) {
                        if (value[0]) {
                            toast.error(value[0]);
                        }
                    });
                } else {
                    toast.error(ress.data.message);
                }
            }
        }).catch(function (e) {
            //toast.error(e.message);
        });
    };

    render() {

        return (
            <SubLayoutReward data={{ "title": strings.profit_details }}>

                <div className='text-center full-width'>
                    <img className="" alt={strings.Home} src={ctcbotLogo} width={(window.innerWidth < 668) ? (window.innerWidth / 2) : 200} />
                </div>

                <div className="main_content">
                    <div className="flex-center" style={{ justifyContent: "space-between", padding: 10 }}>
                        <div className="flex-center">
                            <div className='profile_back' style={{}} >
                                <img className='profile_back' src={(this.state.user_data && this.state.user_data.avatar_url) ? this.state.user_data.avatar_url : Avatar1} alt=""></img>
                            </div>
                            <div style={{ marginLeft: 10, fontSize: 12 }}>
                                <div style={{ fontSize: 14 }}>{(this.state.user_data && this.state.user_data.username) ? this.state.user_data.username : ""}</div>
                                <div style={{ fontSize: 12 }}>{strings.invitation_code}: {(this.state.user_data && this.state.user_data.invitation_code) ? this.state.user_data.invitation_code : ''}</div>
                            </div>
                        </div>
                        <div className='text-center qr_code' style={{ padding: 5, margin: 5 }}>
                            <QRCode size={50} value={(this.state.user_data && this.state.user_data.invitation_code) ? window.location.origin + `/register?invitationcode=${this.state.user_data.invitation_code}` : ''} />
                        </div>
                    </div>
                    <div style={{ fontWeight: "bold", fontSize: 12, marginInline: 10, textTransform: "uppercase" }}>
                        <span style={{ color: "#1cc590" }}>{strings.total_daily_trading_profit}</span> <span style={{ color: "#ebeef1" }}>| {strings.spot_trading}</span>
                    </div>
                    <div style={{ fontWeight: "bold", fontSize: 60, marginBlock: 30, textTransform: "uppercase", textAlign: "center" }}>
                        <span style={{ color: "#1cc590" }}>{(this.state.data.profit_quantification && this.state.data.profit_quantification.profit_today) ? Number.parseFloat(this.state.data.profit_quantification.profit_today).toFixed(2) : 0} {strings.USD}</span>
                    </div>
                    <div className='border_'></div>
                    <div className='row' style={{ margin: 10, }}>
                        <div className='col-6' style={{ alignSelf: "center" }}>
                            <div style={{ fontWeight: "bold", textTransform: "uppercase", lineHeight: 1, textAlign: 'left' }}>
                                <div style={{ color: "#1cc590", fontSize: 26, }}>{"TOTAL"}</div>
                                <div style={{ color: "#1cc590", fontSize: 9, marginLeft: 5 }}>{"monthly profit"}</div>
                            </div>
                        </div>
                        <div className='col-6' style={{ alignSelf: "center" }}>
                            <div style={{ fontWeight: "bold", textTransform: "uppercase", lineHeight: 1, textAlign: 'right' }}>
                                <div style={{ color: "#ebeef1", fontSize: 26, }}>{(this.state.data.profit_quantification && this.state.data.profit_quantification.profit_monthly) ? Number.parseFloat(this.state.data.profit_quantification.profit_monthly).toFixed(2) : 0} {strings.USD}</div>
                            </div>
                        </div>
                    </div>
                    <div className='row' style={{ marginInline: 10, marginBlock: 30, justifyContent: "center" }}>
                        <img src={this.state.image_item} alt="" style={{ width: (window.innerWidth / 1.5) }}></img>
                    </div>
                    <div className='back_reward_footer' style={{ backgroundImage: `url(${rewardfooter})`, fontWeight: "bold", fontSize: 12, marginTop: 20, textTransform: "uppercase", textAlign: "center" }}>
                        <span style={{ color: "#cba38e" }}>{strings.total_profit_earned}</span> <span style={{ color: "#ebeef1", fontSize: 16, marginLeft: 10 }}> {(this.state.data.profit_quantification && this.state.data.profit_quantification.profit_total) ? Number.parseFloat(this.state.data.profit_quantification.profit_total).toFixed(2) : 0} {strings.USD}</span>
                    </div>
                </div>

            </SubLayoutReward>
        );
    }
}

export default ProfitDetailsScreen;